import { GetServerSidePropsContext, GetStaticPropsResult } from 'next'
import ErrorDialog from '@src/sharedBetweenApps/components/ErrorDialog'
import { setCacheControlHeader } from '@src/sharedBetweenApps/lib/utils/cacheControlHeader'

// error page for CloudFront, returns 200

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetStaticPropsResult<any>> {
  setCacheControlHeader(context.res, {
    maxAge: 60, // [1 minutes]
    staleWhileRevalidate: 604800, // [7 days]
    staleIfError: 31557600, // [1 year]
  })
  return {
    props: {},
  }
}

export default function ErrorPage() {
  return <ErrorDialog />
}
